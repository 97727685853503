import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Banner from "../components/banner";
import Articals from "../pages/Articles/articals";
import FullArtical from "../pages/Articles/fullArtical";
import Login from "../auth/login";
import Register from "../auth/register";
import Course from "../pages/Courses/course";
import FullCourse from "../pages/Courses/fullCourse";
import Contact from "../pages/Contact/contact";
import PageNotFound from "../pages/Extra/404";
import Youtube from "../pages/Youtube/youtube";
import FullYoutube from "../pages/Youtube/fullYoutube";
import Home from "../pages/Home/home";
import Profile from "../pages/Profile/profile";
import EditProfile from "../pages/Profile/editProfile";
import AddProfileData from "../pages/Profile/addProfileData";
import Resource from "../pages/Resources/resource";
import FullResource from "../pages/Resources/fullResource";
import WebApps from "../pages/Explore/WebApps";
import AndroidApps from "../pages/Explore/AndroidApps";
import About from "../pages/About/About";
import ProtectedRoute from "../utils/ProtectedRoute";
import Resume from "../pages/Document/resume";

const router = () => {
  return (
    <Router>
      <Banner />
      <Header />
      <Routes>
        <Route exec path="/articles" element={<Articals />} />
        <Route exec path="/fullArticle/:articleId" element={<FullArtical />} />
        <Route exec path="/courses" element={<Course />} />
        <Route exec path="/course/:courseId" element={<FullCourse />} />
        <Route exec path="/contact" element={<Contact />} />
        <Route exec path="/youtube" element={<Youtube />} />
        <Route exec path="/fullYoutube/:youtubeId" element={<FullYoutube />} />
        <Route exac path="/resource" element={<Resource />} />
        <Route exac path="/resource/:id" element={<FullResource />} />
        <Route exac path="/resume" element={<Resume />} />

        <Route exec path="/profile" element={<Profile />} />
        <Route exac path="/profile/add/:id" element={<AddProfileData />} />
        <Route exac path="/profile/edit/:id" element={<EditProfile />} />

        <Route exec path="/" element={<Home />} />
        <Route exec path="*" element={<PageNotFound />} />
        <Route exec path="/webapps" element={<WebApps />} />
        <Route exec path="/androidApps" element={<AndroidApps />} />

        <Route exec path="/login" element={<Login />} />
        <Route exec path="/register" element={<Register />} />

        <Route exec path="/about" element={<About />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default router;
