import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../App";
import Cookies from "js-cookie";

const EditProfile = () => {
  const navigate = useNavigate();
  // make the state for the  profile data and exp data
  const [profile, setProfile] = useState({});
  const [userDetId, setUserDetID] = useState("");

  const [exp, setExperiences] = useState([
    { company: "", role: "", startDate: "", endDate: "", description: "" },
  ]);

  // Make the State to store the data
  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [location, setLocation] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [designation, setDesignation] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState();
  const [githubUrl, setGithubUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [profileDesc, setProfileDesc] = useState("");

  // First Get the Profile Data and fill in the input box
  const token = Cookies.get("user");

  // When Token not found then navigate to the Home Page
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const getProfileData = () => {
    const url = `${baseUrl}/api/v1/auth/profile/${token}`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log("Response while getting the User details Id", response);
        // Updated Code for setting the state.
        const profileData = response.data.data.userDetailId;
        setProfile(profileData);
        setUserDetID(profileData._id);
        setExperiences(profileData.userExp);

        // Set state variables with fetched data
        setName(profileData.name);
        setProfilePic(profileData.profilePic);
        setLocation(profileData.location);
        setDob(profileData.dob);
        setGender(profileData.gender);
        setEducation(profileData.education);
        setDesignation(profileData.designation);
        setResumeUrl(profileData.resume_url);
        setInstagramUrl(profileData.instagram_url);
        setGithubUrl(profileData.github_url);
        setLinkedinUrl(profileData.linkedin_url);
        setPortfolio(profileData.portfolio_website);
        setProfileDesc(profileData.profile_description);
      })
      .catch((error) => {
        console.log("Error while getting the Profile ID", error);
      });
  };

  // use the useeffect hook to call the function automatically
  useEffect(() => {
    getProfileData();
  }, []);

    // Make Function to show the Age when the user enter the dob
    let yourAge = 0;
    const showAge = () => {
      const date = new Date(dob);
      const currentYear = new Date().getFullYear();
      const year = date.getFullYear();
      const newAge = currentYear - year;
      yourAge = newAge;
    };
  
    showAge();

  // Write the function to edit the data
  const handleEdit = (e) => {
    e.preventDefault();

    const url = `${baseUrl}/api/v1/userDetails/edit/${userDetId}`;
    axios
      .put(
        url,
        {
          name,
          profilePic,
          location,
          dob,
          gender,
          education,
          designation,
          age: yourAge,
          resume_url: resumeUrl,
          instagram_url: instagramUrl,
          github_url: githubUrl,
          linkedin_url: linkedinUrl,
          portfolio_website: portfolio,
          profile_description: profileDesc,
          userExp: exp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("Response while Adding the Profile Data ", response);
        alert(response.data.message);
        navigate("/profile");
      })
      .catch((error) => {
        console.log("Error while Adding the Profile Data ", error);
      });
  };

  const handleExperienceChange = (index, field, value) => {
    const newExperiences = [...exp];
    newExperiences[index][field] = value;
    setExperiences(newExperiences);
  };

  const handleAddExp = () => {
    setExperiences([
      ...exp,
      { company: "", role: "", startDate: "", endDate: "", description: "" },
    ]);
  };

  const handleRemoveExp = (index) => {
    const newExp = exp.filter((_, i) => i !== index);
    setExperiences(newExp);
  };

  return (
    <div className="w-3/4 sm:w-2/3 m-auto">
      <div className="mt-10 w-full sm:w-2/3 md:w-1/2">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Update Profile Data
        </h2>
        <p className="text-gray-500 font-normal dark:text-gray-400">
          Check out some of the other recommended blocks from Flowbite to get
          started building your website faster with Tailwind CSS.
        </p>
      </div>

      <div>
        <div className="py-8 flex gap-4">
          <form onSubmit={handleEdit}>
            <div className="flex sm:flex-row flex-col gap-4">
              <div className="w-full sm:w-3/5">
                <div className="grid gap-3 sm:gap-6 grid-cols-1 sm:grid-cols-2">
                  {/* Profile Pic  */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Profile Pic Url
                    </label>
                    <div className="flex gap-2 items-center">
                      <img
                        className="h-20 w-20 rounded-full object-cover"
                        src={
                          profile?.profilePic
                            ? profile?.profilePic
                            : "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/helene-engels.png"
                        }
                        alt=""
                      />
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={profilePic}
                        onChange={(e) => setProfilePic(e.target.value)}
                        className="bg-gray-50 border border-gray-300 p-2.5 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="Profiel Pic Url"
                        required=""
                      />
                    </div>
                  </div>

                  {/* Name  */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Type Your name"
                      required=""
                    />
                  </div>

                  {/* Location  */}
                  <div className="w-full">
                    <label
                      htmlFor="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Patna, Bihar"
                      required=""
                    />
                  </div>
                  {/* Designation  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Designation
                    </label>
                    <input
                      type="text"
                      name="designation"
                      id="designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Software Engineer"
                      required=""
                    />
                  </div>
                  {/* Gender  */}
                  <div>
                    <label
                      htmlFor="gender"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Gender
                    </label>
                    <select
                      id="gender"
                      onChange={(e) => setGender(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  {/* Dob  */}
                  <div>
                    <label
                      htmlFor="item-weight"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="12"
                      required=""
                    />
                  </div>
                  {/* Education  */}
                  <div className="w-full">
                    <label
                      htmlFor="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Education
                    </label>
                    <input
                      type="text"
                      name="education"
                      id="education"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Btech in Computer Science"
                      required=""
                    />
                  </div>
                  {/* Age  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Age
                    </label>
                    <input
                      type="text"
                      name="age"
                      id="age"
                      readOnly
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      value={yourAge}
                      required=""
                    />
                  </div>
                  {/* LinkedIn Url  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      LinkedIn Url
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <i className="fa-brands fa-linkedin"></i>
                      </div>
                      <input
                        type="text"
                        value={linkedinUrl}
                        onChange={(e) => setLinkedinUrl(e.target.value)}
                        id="email-address-icon"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="https://www.linkedin.com/in/username/"
                      />
                    </div>
                  </div>
                  {/* Instagram Url  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Instagram Url
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <i className="fa-brands fa-instagram"></i>
                      </div>
                      <input
                        type="text"
                        value={instagramUrl}
                        onChange={(e) => setInstagramUrl(e.target.value)}
                        id="email-address-icon"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="https://www.instagram.com/username/"
                      />
                    </div>
                  </div>
                  {/* Resume Url  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Resume Url
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <i className="fa-solid fa-link"></i>
                      </div>
                      <input
                        type="text"
                        id="email-address-icon"
                        value={resumeUrl}
                        onChange={(e) => setResumeUrl(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="https://drive.google.com/file/d/1-1t04YIuTrL1rxRnYjTCx6fiCbBHcbF0/view"
                      />
                    </div>
                  </div>
                  {/* Github Url  */}
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Github Url
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <i className="fa-brands fa-github"></i>
                      </div>
                      <input
                        type="text"
                        id="email-address-icon"
                        value={githubUrl}
                        onChange={(e) => setGithubUrl(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="https://github.com/username"
                      />
                    </div>
                  </div>
                  {/* Portfolio Url */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Portfolio Website
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={portfolio}
                      onChange={(e) => setPortfolio(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Type Your Portfolio Url"
                      required=""
                    />
                  </div>

                  {/* Profile Description  */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Profile Description
                    </label>
                    <textarea
                      id="description"
                      rows="8"
                      value={profileDesc}
                      onChange={(e) => setProfileDesc(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      placeholder="Your Profile Description"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-2/5">
                <div>
                  <div className="sm:col-span-2 mb-5">
                    <h1 className="font-bold text-2xl mb-2 dark:text-white">
                      Experience
                    </h1>
                    <p className="text-gray-500 font-normal dark:text-gray-400">
                      Please Enter the experience data like what are you doing
                      now, your past overall experience in compnay or
                      organization.
                    </p>
                  </div>

                  {/* Experience Data Multiple  */}
                  <div className="flex flex-col gap-4">
                    {exp.map((expItem, index) => (
                      <div
                        key={index}
                        className="grid gap-3 sm:gap-6 grid-cols-1 sm:grid-cols-2"
                      >
                        <h1 className="font-bold dark:text-white">
                          Company {index}
                        </h1>
                        {/* Company Name */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor={`company-${index}`}
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Company
                          </label>
                          <input
                            type="text"
                            id={`company-${index}`}
                            value={expItem.company}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                "company",
                                e.target.value
                              )
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            placeholder="Type Company"
                            required
                          />
                        </div>

                        {/* Your Role */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor={`role-${index}`}
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Your Role
                          </label>
                          <input
                            type="text"
                            id={`role-${index}`}
                            value={expItem.role}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                "role",
                                e.target.value
                              )
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            placeholder="Type Your Role"
                            required
                          />
                        </div>

                        {/* Start Date */}
                        <div className="w-full">
                          <label
                            htmlFor={`startDate-${index}`}
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            id={`startDate-${index}`}
                            value={expItem.startDate}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                "startDate",
                                e.target.value
                              )
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            required
                          />
                        </div>

                        {/* End Date */}
                        <div className="w-full">
                          <label
                            htmlFor={`endDate-${index}`}
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            id={`endDate-${index}`}
                            value={expItem.endDate}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                "endDate",
                                e.target.value
                              )
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            required
                          />
                        </div>

                        {/* Job Description */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor={`description-${index}`}
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Job Description
                          </label>
                          <textarea
                            id={`description-${index}`}
                            rows="8"
                            value={expItem.description}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            placeholder="Your job description here"
                          ></textarea>
                        </div>

                        <button
                          type="button"
                          onClick={() => handleRemoveExp(index)}
                          className="mt-2 text-red-600 bg-red-100 dark:text-white dark:bg-red-500 rounded-lg p-2 font-semibold"
                        >
                          Remove
                        </button>
                      </div>
                    ))}

                    {/* Add More Experience Button */}
                    <button
                      type="button"
                      onClick={handleAddExp}
                      className="mt-2 text-purple-600 bg-purple-100 dark:text-white dark:bg-purple-600 rounded-lg p-2 font-semibold"
                    >
                      Add Experience
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-purple-700 rounded-lg focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-900 hover:bg-purple-800"
            >
              Edit Profile Data
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
